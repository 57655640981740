// Custom variables and Bootstrap
@use "../custom" as *;

// Local Google Fonts
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../assets/fonts/Montserrat-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../assets/fonts/Montserrat-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../assets/fonts/Montserrat-Bold.woff2') format('woff2');
}

// LOGO
.svg-primary {
    fill: $primary;
    stroke-width: 0px;
}

.svg-gray {
    fill: $gray-900;
    stroke-width: 0px;
}

.svg-gray-subtle {
    fill: $gray-400;
    stroke-width: 0px;
}

// FOOTER LOGO
.svg-white {
    fill: $white;
    stroke-width: 0px;
}

// HOVER FLOAT
.hover-float {
    position: relative;
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: translateY(-5px);
    }
}

// DOTS BG
.dots-bg {
    position: relative;
    
    &.page-intro:before {
        content: '';
        background-image: radial-gradient(circle at 8px 8px, var(--bs-white) 8px, transparent 0);
        background-size: 36px 36px;
        position: absolute;
        top: -50vw;
        right: 0;
        bottom: -50vw;
        left: -100vw;
        transform: rotate(-56deg);
        transform-origin: top right;
        opacity: 0.1;
        pointer-events: none;
    }

    &.blog-intro:before {
        content: '';
        background-image: radial-gradient(circle at 8px 8px, var(--bs-gray-500) 8px, transparent 0);
        background-size: 36px 36px;
        position: absolute;
        right: -20vw;
        bottom: 0;
        left: -20vw;
        height: 108px;
        transform: rotate(-2deg);
        transform-origin: top right;
        opacity: 0.15;
        pointer-events: none;
    }

    &.content-section:before {
        content: '';
        background-image: radial-gradient(circle at 8px 8px, var(--bs-gray-500) 8px, transparent 0);
        background-size: 36px 36px;
        position: absolute;
        top: -50vw;
        right: 0;
        bottom: -50vw;
        left: -100vw;
        transform: rotate(-56deg);
        transform-origin: top right;
        opacity: 0.15;
        pointer-events: none;
    }
    
}