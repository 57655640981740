// Custom variables and Bootstrap
@use "../custom" as *;

.blog-post-width {
    max-width: 840px;
}

.blog-post {

    strong {
        font-weight: 500;
    }

    hr {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    // Lists
    ul {
        list-style: none;
        padding-left: 0;

        li {
            position: relative;
            padding-left: 2.5rem;
            margin-bottom: 0.5rem;

            &::before {
                content: '\F270';
                font-family: bootstrap-icons !important;
                font-size: 1rem;
                position: absolute;
                left: 1rem;
                top: 1px;
                color: $primary;
            }
        }
    }

    // Tables
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        margin: $spacer 0;
        line-height: 1.5;
        border: 1px solid $gray-300;

        thead {
            background: $gray-800;
            color: #fff;
            text-align: left;
        }

        th, td {
            padding: $spacer * 0.75;
            border: 1px solid $gray-300;
        }

        th {
            border-color: $gray-500;
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background: $gray-100;
                }

                &:nth-child(even) {
                    background: $white;
                }
            }
        }

        /* Responsive Table */
        @include media-breakpoint-down(md) {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
        }
    }

    // Images
    @include media-breakpoint-down(md) {
        .blog-post-width {
            img {
                border-radius: 1rem!important;
            }
        }
    }
}